<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span >Update Instruction</span>
            </div>
          </div>
          <div class="p-4">
            <div class=" mb-4">
            <TextAreaInput
            :inputId="'Instructionid'"
            :inputext="instruction"
            :placholderText="`Write instruction here...`"
            :lableText="`Job ${reqType === 'notes' ? 'Instruction' : 'Title'}`"
            :inputType="'text'"
            :autoFocus="true"
            :fieldError="titleErr !== ''"
            @keyPressAction="titleErr = ''"
            :textMaxlength="1000"
            :showcharLimit="true"
            :cols="50"
            :rows="5"
            :setReadOnly="false"
            @inputChangeAction="(data) => instruction = data"  />
           <p v-if="titleErr !== ''" class="text-error heading-7">{{titleErr}}</p>
            </div>
            <div class="flex flex-wrap gap-4 mb-4" v-if="presentAttch.length > 0">
              <div class=" card rounded-sm border-t border-gray-100 bg-white attachment-card cursor-pointer group relative" style="width: 110px; height: 76px"  v-for="(attach, a) in presentAttch " :key="a">
                <div v-if="attach.docType === 'image'" class="text-center flex justify-center">
                    <img style="height: 60px;width: auto;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                    <p v-if="attach.addedById === 0"><i class="absolute right-5 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                </div>
                <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                  <img style="height: 60px;width: auto;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                  <img class="flex items-center  text-4xl absolute text-white " height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                  <p v-if="attach.addedById === 0"><i class="absolute right-5 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                </div>
                <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                  <p v-if="attach.addedById === 0"><i class="absolute right-5 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                </div>
                <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                  <p v-if="attach.addedById === 0"><i class="absolute right-5 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                </div>
                <div class="text-text2 text-center heading-8">{{attach.uploadedOn | dateFilter1}}</div>
                <span @click="removeAttach(attach,a)" class=" absolute top-0 cursor-pointer right-0 bg-white rounded-full flex justify-center heading-3 p-0.5 border-primary"><i class="fa-solid fa-circle-xmark"></i></span>
               </div>  
            </div>
            <div class="col-span-12">
                <input type="file" accept=".pdf, .jpg, .jpeg, .png, .gif, .mp4, .mov, .avi"  ref="file" multiple style="display: none" @change="uploadFiles"/>
                <a
                  @click="$refs.file.click()"
                  class="heading-6 ml-2 text-primary cursor-pointer"
                >
                + Add Attachment
                </a>
              </div>
              <div class=" col-span-12">
                <div>
                  <div class="" v-for="(data, index) in fileName" :key="index" >
                    <p class="text-text1 font-bold" v-if="index === 0">
                      File(s) attachment
                    </p>
                    <div v-if="data.uploadedFile" class=" flex">

                      <div class="flex pr-2">
                        <span class="text-gray3 whitespace-nowrap pr-2">File Name</span>
                        <div style="display: grid;">
                            <span class="mb-0 mt-0 subtitle-2 text-text2" style="width: 100%; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ data.file.name.split('.')[0]}}</span>
                        </div>
                        <span class="text-text2">.{{data.file.name.split('.')[1]}}</span>
                        </div>

                      <p :class="removingFile ? `text-gray3` : `text-black`">
                      
                      <span @click="removeFile(index)"><i class="fas fa-minus-circle text-error" style="margin-top:-3px" ></i></span>
                      <span class="black--text" v-if="data.removingFile">
                        Removing File......
                      </span>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNote()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'addUpdatenote',
  components: {
    TextAreaInput,
    Button,
  },
  data () {
    return {
      ImageSizePermission: FilterPermissions('max_image_upload_size'),
      VideoSizePermission: FilterPermissions('max_video_upload_size'),
      deletdArr: [],
      presentAttch: [],
      ImageCount: 0,
        VideoCount: 0,
        usedStorageVal: 0,
        instruction: '',
        titleErr: '',
        fileName: [],
        attachmentList: [],
        fileUpload: false,
        removingFile: false,
        files: [],
    }
  },
  props: ['note', 'reqType', 'attachment', 'pathPrefix'],
   created() {
  },
  mounted () {
    this.getStorageInfo()
    console.log('this.attachment', this.attachment)
    this.presentAttch = this.attachment !== null ? this.attachment : []
    this.instruction = this.note
    document.body.style = 'overflow: hidden;'
  },
  watch: {
    instruction: {
      handler () {
        if (this.instruction.trim() !== '') {
            this.saveDisabled = false
        } else if (this.files.length === 0) {
            this.saveDisabled = true
        } else {
            this.saveDisabled = false
        }
      },
      deep: true
    },
    presentAttch: {
      handler () {
        if (this.presentAttch.length === 0) {
          this.saveDisabled = true
        } else {
          this.saveDisabled = false
        }
      },
      deep: true
    },
  },
  methods: {
    removeAttach (data, index) {
      this.deletdArr.push(data.fileVaultId)
      this.presentAttch.splice(index, 1)
    },
    saveNote () {
        let isValid = true
        let reqObj = null
        if (this.reqType === 'notes') {
          reqObj =  {
            jobId: parseInt(this.$route.params.jobId),
            jobDescription: this.instruction
          }
        }
        if (this.reqType === 'jobTitle') {
            if (this.instruction.trim() === '') {
                isValid = false
                this.titleErr = 'Job Title in required'
            }
          reqObj =  {
            jobId: parseInt(this.$route.params.jobId),
            jobTitle: this.instruction
          }
        }
        console.log("this.fileNamethis.fileNamethis.fileNamethis.fileNamethis.fileNamethis.fileNamethis.fileName", this.fileName);
       if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
       MyJobApp.updateJobDetail(
        this.files,
        this.reqType,
         reqObj,
         this.deletdArr,
        response => {
          document.body.style = 'overflow: visible;'
          this.$root.$emit('updateJobHandler', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
       }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('updateJobHandler', false)
    },
     async uploadFiles (event) {
      this.fileUpload = true
      for (let i = 0; i < event.target.files.length; i++) {
        let Isvalid = true
        let file = event.target.files[i]
        let imageSize = this.ImageSizePermission.v === undefined || this.ImageSizePermission.v === 0 ? 1048576 : this.ImageSizePermission.v * 1048576
        let videoSize = this.VideoSizePermission.v === undefined || this.VideoSizePermission.v === 0 ? 5242880 : this.VideoSizePermission.v * 1048576
        console.log('imageSize', imageSize)
        console.log('videoSize', videoSize)
        console.log('file.type', file.type)
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/svg' || file.type === 'application/pdf') {
            if (file.size < imageSize) {
              this.ImageCount += 1
              Isvalid = true
              this.files.push(event.target.files[i])
            } else {
              Isvalid = false
              this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(imageSize)}MB size image`, color: 'error'})
            }
          } else {
            if (file.size < videoSize) {
              Isvalid = true
              this.VideoCount += 1
              this.files.push(event.target.files[i])
            } else {
              Isvalid = false
              this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(videoSize)}MB size video`, color: 'error'})
            }
          }
          if (Isvalid === true) {
            let newDate = new Date().getTime()
            let obj = {
              file: file,
              id: newDate,
              uploadedFile: true,
              attachedDocument: false,
              documentName: '',
              documentNotes: '',
              progressLinearValue: 0,
              source: ''
            }
            this.fileName.push(obj)
          }
        }
      },
      convertToMb (kb) {
        return kb / (1024 * 1024);
      },
    removeFile (index) {
      this.fileName.splice(index, 1)
      const fileListArr = Array.from(this.files)
      fileListArr.splice(index, 1)
      this.files = fileListArr
    },
    getStorageInfo () {
      MyJobApp.GetUsedStorageDetail(
        response => {
          let fileSizeMB = response.Data.usedStorage / (1024 ** 2)
          this.usedStorageVal = fileSizeMB.toFixed(2)
          console.log('usedStorageVal', this.usedStorageVal)
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>
.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: fit-content;
    /* width: 750px; */
    justify-content: center;
    text-align: center;
}

.dropzone-container {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 20px;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
}
.zIndex {
  z-index: 100 !important;
}
.preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}


.progress-bar {
  height: 5px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}
 .progressDiv {
    border: grey solid 1px !important;
    padding: 5px;
    font-size: small;
    position: relative;
}
.progressCancelButton {
    position: absolute;
    top:10px;
    right:1px;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>