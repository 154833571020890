<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative bg-screen_bg">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span >Update Address</span>
            </div>
          </div>
          <div class="p-4">
            <div class="flex gap-4 justify-between">
             <div v-if="userAddress !== null" @click="showOnMap(userAddress)" class=" w-full cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span class="pr-1"><i class="fa-solid fa-location-crosshairs"></i></span>
                    <span>Current Location</span>
                </div>
            </div>
             <div v-if="OfficelatLong !== null" @click="showOnMap(OfficelatLong)" class="w-full cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span class="pr-1"><i class="fa-solid fa-location-dot"></i></span>
                    <span>Locate on Map</span>
                </div>
            </div>
           </div>
            <div v-if="userAddress !== null || OfficelatLong !== null">
              OR
            </div>
            <div @click="addNewjobAdd = true" class="cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span>Search New Address</span>
                </div>
            </div>
            <p class="heading-5 font-semibold  text-text2">Current selected address</p>
            <div class=" mb-4">
                <div>
                    <div @click="showOnMap(jobDetail)" class="cust_card p-2 mb-2 text-text1 cursor-pointer bg-white">
                        <div class="flex justify-between gap-1">
                          <span class=" border border-gray2 rounded-md heading-7 px-1">
                            {{jobDetail.addressTitle === '' ? 'Other' : jobDetail.addressTitle}}
                          </span>
                          <p class="text-error heading-7">{{currentAddressDistance}}</p>
                        </div>
                        <div class="" >
                            {{jobDetail.fullAddress}}
                        </div> 
                    </div>
                </div>
            </div>
            <p class="heading-5 font-semibold text-text2">Select from Saved Address in Customer File</p>
            <div class=" mb-4">
                <div v-for="(add,ind) in addressList" :key="ind">
                  <div @click="selectJobAddress(add, ind)" class="cursor-pointer bg-white mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                      <span class=" border border-gray2 rounded-md heading-7 px-1">
                        {{add.addressTitle === '' ? 'Other' : add.addressTitle}}
                      </span>
                      </div>
                      <p class="text-error heading-7">{{add.displayDistance}}</p>
                    </div>
                    <div>
                      <p>{{add.fullAddress}}</p>
                    </div>
                  </div>
                </div>  
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-screen_bg w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'white'" :btnText="'Discard'" @buttonAction="discard()"/>
            </div>
            </div>
        </div>
    </div>
    <AddressAdd :showAdditional="true" v-if="addNewjobAdd" />
    <EditAddress :jobDetail="addressObj" v-if="addressObj !== null" :showAdditional="true" />
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import deboucneMixin from '@/mixins/debounce.js'
import EditAddress from '../../components/EditAddress.vue'
import AddressAdd from '../../components/AddressAdd.vue'
import timeZoneWithCountryCode from "@/assets/timeZoneWithCountryCode.json";
import milesCountry from "@/assets/milesCountry.json";

/* global google */
// import GmapCircle from 'vue2-google-maps/dist/components/circle'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import store from '@/store'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  name: 'addUpdatenote',
  components: {
    Button,
    EditAddress,
    AddressAdd,
  },
  mixins: [deboucneMixin],
  data () {
    return {
        addNewjobAdd: false,
        jobDisplayAdd: '',
        addressList: [],
        milsCList: [],
        addressErr: '',
        customerId: 0,
        addressObj: null,
        userAddress: null,
        userLat: 0,
        userLong: 0,
        LoginUserlatLong: null,
        OfficelatLong: null,
        currentAddressDistance: '',
    }
  },
  props: ['jobDetail'],
   created() {
  },
  mounted () {
    this.LoginUserlatLong = JSON.parse(
      localStorage.getItem("LoginUserlatLong")
    );
    this.OfficelatLong = JSON.parse(localStorage.getItem('orgInfo'))
    console.log('LoginUserlatLong', this.LoginUserlatLong);
    if ("geolocation" in navigator) {
      // Request permission to access location
 
      navigator.geolocation.getCurrentPosition(
        (position) => {  // Use an arrow function here
          // Get coordinates
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);

          if (position.coords.latitude && position.coords.longitude) {
            this.userLat = position.coords.latitude;
            this.userLong = position.coords.longitude;      
          }
        },
        (error) => {
          console.error("Error obtaining location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    console.log('jobDetail , ', this.jobDetail);
    this.customerId = this.jobDetail.customerId
    this.getCustDetails()


    this.$root.$on('EditAddressHandler', () => {
      console.log('check update add page----');
        this.addressObj = null
    })
    this.$root.$on('addAddressHandler', (response, data) => {
      if (response && data !== null && this.addNewjobAdd) {
      this.$root.$emit('updateAddressHandler', true, data)
      }
      this.addNewjobAdd = false
    })
    if (this.jobDetail.fullAddress !== '') {
      this.getSelectedAddressDistance()
    }
  },
  watch: {
    userLat: {
      handler() {
      if (this.userLat !== 0 && this.userLong !== 0) {
        this.callFunt()
        this.getAddress(this.userLat, this.userLong)
      }
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("addAddressHandler");
  },
  methods: {
    getSelectedAddressDistance() {
      let temp = 0;
              let temp3 = "";
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(
                  this.LoginUserlatLong.latitude,
                  this.LoginUserlatLong.longitude,
                  this.jobDetail.latitude,
                  this.jobDetail.longitude
                );
                temp3 = ` from user`;
              } else if (this.OfficelatLong !== null) {0
                temp = this.calcDistance(
                  this.OfficelatLong.latitude,
                  this.OfficelatLong.longitude,
                  this.jobDetail.latitude,
                  this.jobDetail.longitude
                );
                temp3 = ` from office`;
              }

              let value = this.getDistanceUnit(temp * 1000);
              this.currentAddressDistance = value + temp3
    },
    callFunt() {
      console.log('check call 1');
    },
    getAddress(lat, lng) {
      console.log('check call 2');
      let newAddress = ''
      var geocoder = new google.maps.Geocoder;
      var latlng = {lat: lat, lng: lng};
      let addressComponents = {};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK') {
          if (results[1]) {
            newAddress = results[1].formatted_address

        const components = results[1].address_components;

        components.forEach(component => {
          const types = component.types;
          if (types.includes('street_number')) {
            addressComponents.houseNo = component.long_name;
          }
          if (types.includes('route')) {
            addressComponents.addressLine1 = component.long_name;
          }
          if (types.includes('locality')) {
            addressComponents.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            addressComponents.state = component.long_name;
          }
          if (types.includes('country')) {
            addressComponents.country = component.long_name;
          }
          if (types.includes('postal_code')) {
            addressComponents.zip = component.long_name;
          }
          // Add more address component types as needed
        });

          } else {
            window.alert('No results found');
          }
        }
      })
      let center = {
        lat: latlng.lat,
        lng: latlng.lng
      }
      let markers = []
        markers.push({ position: center })
        console.log("center--------------------------", center);
        console.log("markers--------------------------", markers);
      setTimeout(() => {
          
        this.userAddress = {
          addressLine1: addressComponents.addressLine1 || "",
          addressLine2: addressComponents.addressLine2 || "",
          addressTitle: addressComponents.addressTitle || "",
          buildingName: addressComponents.buildingName || "",
          city: addressComponents.city || "",
          country: addressComponents.country || "",
          directionNote: addressComponents.directionNote || "",
          floorNumber: addressComponents.floorNumber || "",
          fullAddress: newAddress,
          houseNo: addressComponents.houseNo || "",
          latitude: latlng.lat,
          longitude: latlng.lng,
          state: addressComponents.state || "",
          zip: addressComponents.zip || "",
        }
        console.log("newAddress--------------------------", this.userAddress);
      }, 500);
    },
    LocateOnMap () {
        this.addressObj = this.jobDetail
    },
    showOnMap (data) {
        this.addressObj = data
    },
    discard () {
      this.$root.$emit('updateAddressHandler', false, null)
    },
     setCustomerDetail() {
      this.searchForCustomer = this.custDetail.companyName;
          console.log("response.Data----------------------", this.custDetail);
          if (this.custDetail.addressList !== null) {
            this.custDetail.addressList.forEach(e => {
              let temp = 0;
              let temp3 = "";
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(
                  this.LoginUserlatLong.latitude,
                  this.LoginUserlatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from user`;
              } else if (this.OfficelatLong !== null) {0
                temp = this.calcDistance(
                  this.OfficelatLong.latitude,
                  this.OfficelatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from office`;
              }

              let value = this.getDistanceUnit(temp * 1000);

              this.addressList.push({
                ...e,
                selected: false,
                displayDistance: value + temp3,
                addressText:
                  `(${e.addressTitle === "" ? "Other" : e.addressTitle}) -` +
                  ` ${e.fullAddress}`,
              });
            });
          }
          if (this.addressList.length === 1) {
            this.jobDisplayAdd = this.addressList[0].fullAddress;
            this.addJobObj.addressLine1 = this.addressList[0].addressLine1;
            this.addJobObj.addressLine2 = this.addressList[0].addressLine2;
            this.addJobObj.addressTitle = this.addressList[0].addressTitle;
            this.addJobObj.city = this.addressList[0].city;
            this.addJobObj.state = this.addressList[0].state;
            this.addJobObj.zip = this.addressList[0].zip;
            this.addJobObj.latitude = this.addressList[0].latitude;
            this.addJobObj.longitude = this.addressList[0].longitude;
            this.addJobObj.country = this.addressList[0].country;
            this.addJobObj.directionNote = this.addressList[0].directionNote;
            this.addJobObj.houseNo = this.addressList[0].houseNo;
            this.addJobObj.floorNumber = this.addressList[0].floorNumber;
            this.addJobObj.buildingName = this.addressList[0].buildingName;
          }
    },
    getCustDetails() {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading..." });
      MyJobApp.getCustomerDetail(
        this.customerId,
        (response) => {
          this.custDetail = response.Data;
          this.setCustomerDetail()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    setAddress(data, isNotNew) {
      if (!isNotNew) {
        this.isAddedNewAddress = true;
        this.addJobObj.isAddressAddToCustomer = true;
      }
      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.addJobObj.addressTitle = data !== null ? data.addressTitle : "";
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.addJobObj.city = data !== null ? data.city : "";
      this.addJobObj.state = data !== null ? data.state : "";
      this.addJobObj.zip = data !== null ? data.zip : "";
      this.addJobObj.latitude = data !== null ? data.latitude : 0;
      this.addJobObj.longitude = data !== null ? data.longitude : 0;
      this.addJobObj.country = data !== null ? data.country : "";
      this.addJobObj.geofenceRangeDiameter =
        data !== null ? data.selectedRadius : "";
      this.addJobObj.fullAddress = data !== null ? data.fullAddress : "";
      this.addJobObj.houseNo = data !== null ? data.houseNo : "";
      this.addJobObj.floorNumber = data !== null ? data.floorNumber : "";
      this.addJobObj.buildingName = data !== null ? data.buildingName : "";
      this.addJobObj.directionNote = data !== null ? data.directionNote : "";
      this.addJobObj.contactNumber = data !== null ? data.contactNumber : "";
      this.addJobObj.contactName = data !== null ? data.contactName : "";

      
      if (this.LoginUserlatLong !== null) {
        let dist = this.calculateDistance(this.addJobObj.latitude,this.addJobObj.longitude, this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, 'k')
        let final = Number(dist) > 999 ? '999+' : Math.round(Number(dist)) 
        final +=  ' km from User'
        this.displayDistance = final
      } else if (this.OfficelatLong !== null) {
        let dist = this.calculateDistance(this.addJobObj.latitude,this.addJobObj.longitude, this.OfficelatLong.latitude, this.OfficelatLong.longitude, 'k')
        let final = Number(dist) > 999 ? '999+' : Math.round(Number(dist)) 
        final +=  ' km from Office'
        this.displayDistance = final
      }

    },
    selectJobAddress(data) {
      this.isAddedNewAddress = false;
      this.addJobObj.isAddressAddToCustomer = false;
      this.jobDisplayAdd = data !== null ? data.addressText : "";
      this.addJobObj.addressTitle = data !== null ? data.addressTitle : "";
      this.addJobObj.fullAddress = data !== null ? data.fullAddress : "";
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.addJobObj.city = data !== null ? data.city : "";
      this.addJobObj.state = data !== null ? data.state : "";
      this.addJobObj.zip = data !== null ? data.zip : "";
      this.addJobObj.latitude = data !== null ? data.latitude : 0;
      this.addJobObj.longitude = data !== null ? data.longitude : 0;
      this.addJobObj.country = data !== null ? data.country : "";
      this.addJobObj.directionNote = data !== null ? data.directionNote : "";
      this.addJobObj.houseNo = data !== null ? data.houseNo : "";
      this.addJobObj.floorNumber = data !== null ? data.floorNumber : "";
      this.addJobObj.buildingName = data !== null ? data.buildingName : "";
    },
    getDistanceUnit(distanceMeter) {
      let distance = "";
      let countryCode;
      const moment = require("moment-timezone");
      const countryTimeZone = moment.tz.guess(); // Guesses the user's time zone
      timeZoneWithCountryCode.map((itm) => {
        const ind = itm.timezones.findIndex((x) => x.name === countryTimeZone);
        if (ind >= 0) {
          countryCode = itm.code;
        }
      });
      const index = milesCountry.findIndex(
        (x) => x.countryCode === countryCode
      );
      if (index >= 0) {
        if (distanceMeter * 0.00062137 >= 1000) {
          distance = `999+ mi`;
        } else if (distanceMeter * 0.00062137 < 0.1) {
          distance = "";
        } else {
          distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
        }
      } else if (distanceMeter / 1000 >= 1000) {
        distance = `999+ km`;
      } else if (distanceMeter / 1000 < 0.1) {
        distance = "";
      } else {
        distance = `${(distanceMeter / 1000).toFixed(1)} km`;
      }
      return distance;
    },
    calcDistance(lat1, lon1, lat2, lon2) {
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRadians(lat1)) *
          Math.cos(this.toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
  },
}
</script>
<style scoped>
</style>