<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px; max-height: 85%;">
      <div class="relative bg-white">
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                Item Edit
            </div>
          </div>
        <div style="max-height: 65vh" class="overflow-auto">
          <div class="p-4 text-text2">
            <div class=" font-bold mb-2 heading-3 text-text1">{{itemDetail.itemName}}</div>
            <div class=" heading-6 mb-4 ">Code:{{itemDetail.itemCode}}</div>
            <div class=" mb-4 sm:grid grid-cols-12">
                <div class=" mb-4 sm:mb-0 col-span-4">
                    <p class="heading-6 mb-2">Item Qty</p>
                    <div class="flex items-center gap-8">
                        <button
                            @click="minusQnty()"
                            :disabled="itemDetail.itemQty === 1"
                            :class="itemDetail.itemQty > 1  ? ' text-white' : ' text-white'"
                            class="outline outline-1 w-fit bg-primary outline-gray-200  outline-gray rounded py-2 px-2.5 shadow flex justify-center items-center"
                            :color="`primary`">
                            <span class="flex items-center"><i class="fa fa-minus heading-5"></i></span>
                        </button>
                        <div  class="text-text1 flex items-center">{{itemDetail.itemQty}}</div>
                        <button
                            @click="addQnty()"
                            class="bg-primary text-white outline outline-1 w-fit outline-gray-200  outline-gray rounded py-1 px-2.5 shadow"
                            :color="`primary`">
                            <span class=""><i class="fa fa-plus heading-5"></i></span>
                        </button>
                    </div>
                </div>
                <div class=" col-span-8">
                    <div class="mb-4">
                        <TextField
                        :inputId="`itmprice`"
                        :inputext="itemDetail.itemPrice"
                        :placholderText="`Item Price`"
                        :lableText="'Item Price'"
                        :inputType="'number'"
                        :textRight="true"
                        :autoFocus="false"
                        :setReadOnly="false"
                        :inputTypeText="'number'"
                        @inputChangeAction="setItemPrice" />
                        <!-- <NumberInput
                        :inputId="'itmprice'"
                        :inputext="itemDetail.itemPrice"
                        :placholderText="`Item Price`"
                        :lableText="'Item Price'"
                        :inputType="'text'"
                        :textRight="true"
                        :autoFocus="false"
                        :setReadOnly="false"
                        @inputChangeAction="setItemPrice"/> -->
                    </div>
                    <div>
                        <p class="text-text1 font-bold">Item Amount <span v-if="this.itemDetail.isTaxable">(A)</span></p>
                        <TextField
                        :inputId="`itmamt`"
                        :inputext="itemDetail.itemAmount"
                        :placholderText="`Item Amount`"
                        :lableText="'Item Price'"
                        :inputType="'number'"
                        :textRight="true"
                        :autoFocus="false"
                        :setReadOnly="false"
                        :inputTypeText="'number'"
                        @inputChangeAction="setItemAmount" />
<!-- 
                        <NumberInput
                        :inputId="'itmamt'"
                        :inputext="itemDetail.itemAmount"
                        :placholderText="`Item Amount`"
                        :lableText="''"
                        :inputType="'text'"
                        :textRight="true"
                        :autoFocus="false"
                        :setReadOnly="false"
                        @inputChangeAction="setItemAmount"  /> -->
                    </div>
                </div>
            </div>
            <div>
                <div>
                <div class=" flex items-center">
                    <div>
                        <CheckBoxInput
                            :checkValue="itemDetail.isTaxable"
                            :label="``"
                            :disabled="false"
                            @setIsActive="itemDetail.isTaxable = !itemDetail.isTaxable; removeTaxeble()"  />
                    </div>
                    <p  @click.stop.prevent="itemDetail.isTaxable = !itemDetail.isTaxable; removeTaxeble()" class="text-text2 font-bold pl-2">Item Taxable</p>
                      
                </div>
                <p v-if="taxlistErr" class="text-error heading-7 my-2">Please select Tax for taxable item or uncheck item taxable option</p>
                <div>
                    <div v-for="(taxes, index) in finalTaxList" :key="index" :class="`flex ${index < finalTaxList.length - 1 ? 'border-b' : ''} py-2 heading-6`">
                        <div class="w-full">
                            <div class="flex justify-between">
                                <div class="text-gray4 flex items-center">
                                    <div class="pl-1 mr-4">
                                        <span @click="deleteItemTax(taxes.taxId)" class="text-error cursor-pointer heading-3">
                                       <i class="fa-regular fa-trash-can"></i>
                                        </span>
                                    </div>
                                    <div>
                                        {{taxes.taxName.substr(0, 20)}} 
                                        <span v-if="taxes.taxName.length > 20">...</span>
                                        <p class="text-gray4">{{taxes.taxValue}} %</p>
                                    </div>
                                </div>
                                
                                <div>
                                  <p class="text-gray4 text-right">{{taxes.ammount.toFixed(2) | amountOnlyNumber}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="itemDetail.isTaxable" class="text-primary my-4"><span  @click.stop.prevent=" addTaxinput = true;" class="cursor-pointer">+ Add Tax</span></div>

                        <div class="mb-4" v-if="addTaxinput">
                            <div class=" bg-white">
                                <MultiSelect 
                                :inputext="searchForItem"
                                :inputId="'itemtaxsearchid'"
                                :lableText="'Select Tax'"
                                :placholderText="`Search tax`"
                                :autoFocus="true"
                                :showArrow="true"
                                :setReadOnly="false"
                                :showselectAll="false"
                                :searchCallApi="true"
                                :showPlus="true"
                                :keyName="'displayText'"
                                :idKey="'taxId'"
                                :sectionId="'taxlistsecid'"
                                :listId="'taxlistid'"
                                :selectedValues="selectedTaxList"
                                :items="itemtaxList"
                                @closeList="addTaxinput = false"
                                @inputChangeAction="searchTaxes"
                                @selectNewForVal="selectNewTaxes"
                                @addNew="addNewTax"
                                />
                            </div>
                        </div>
                    <div v-if="this.itemDetail.isTaxable" class="flex justify-between border-b border-t py-2 text-text1 font-bold">
                        <div class=" w-full text-right">Total Tax (B)</div>
                            <div class="w-full">
                              <p class=" text-right">{{taxesAmmount.toFixed(2) | amountOnlyNumber}}</p>
                            </div>
                    </div>
                    <div v-if="this.itemDetail.isTaxable" class="flex justify-between border-b py-2 text-text1 font-bold">
                        <div class=" w-full text-right">Total (A + B)</div>
                        <div class="w-full">
                            <!-- <p class=" text-right">{{( parseInt(taxesAmmount) + parseInt(itemDetail.itemAmount) ) | amountOnlyNumber}}</p> -->
                            <p class=" text-right">{{(taxesAmmount + itemDetail.itemAmount).toFixed(2) | amountOnlyNumber}}</p>
                        </div>
                    </div>
                     <div class="my-4">
                    <TextAreaInput
                      :inputId="'itemdescrptionid'"
                      :inputext="itemDetail.itemDescription"
                      :placholderText="`Write description here...`"
                      :lableText="'Item Description'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :cols="50"
                      :rows="2"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => itemDetail.itemDescription = data"  />
                </div>
                <div class="my-4 flex">
                <p class="text-error cursor-pointer" @click="removeConfirm = true">Remove this item</p>   
                </div>
                </div>
                </div>
            </div>
          </div>
        </div>
          <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItems()"/>
            </div>
            </div>
        </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Delete this item from this invoice.'" :title="'Are you sure?'" />
    <AddnewItemsTax v-if="addNewItemTax" />
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
// import TextField from '@/View/components/textfield.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import AddnewItemsTax from './AddnewItemsTax.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
// import NumberInput from '@/View/components/number-text-field.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
export default {
  name: 'sendEmail',
  components: {
    AddnewItemsTax,
    MultiSelect,
    Button,
    TextField,
    CheckBoxInput,
    // NumberInput,
    TextAreaInput,
    ConfirmBox,
  },
  mixins: [deboucneMixin],
  data () {
    return {
        taxlistErr: false,
        removeConfirm: false,
        addTaxinput: false,
        addNewItemTax: false,
        searchForItem: '',
        itemDetail: null,
        finalTaxList: [],
        itemtaxList: [],
        selectedTaxList: [],
        taxesAmmount: 0,
        getTaxesDataList: () => {}
    }
  },
  props: ["selectedItemForDetail"],
   created() {
    this.itemDetail = JSON.parse(JSON.stringify(this.selectedItemForDetail)) 
  },
  mounted () {
    document.body.style = 'overflow: hidden;'
    if (this.itemDetail !== null) {
        if (this.itemDetail.taxRateList !== null) {
             let templist = []
                this.itemDetail.taxRateList.map((data) => {
                    templist.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
                }) 
            this.selectedTaxList = templist 
        this.calculateTaxes(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
        }

    }
    this.$root.$on('confirmBoxHandler', (response) => {
        if (response) {
            this.$root.$emit('removeSelectDetailHandler', true, this.itemDetail)
        }
        document.body.style = 'overflow: visible;'
        this.removeConfirm = false
    })
    this.$root.$on('addNewTaxHandler', (response, newTax) => {
        if (response && newTax.length > 0) {
            newTax.map((data) => {
                this.selectedTaxList.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
                this.itemDetail.taxRateList.push(data)
            }) 
            
            setTimeout(() => {
                this.getTaxesList()
                this.taxesAmmount = 0
                this.calculateTaxes(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
            }, 500);
        }
        this.addNewItemTax = false
    })
    this.getTaxesList()
    this.getTaxesDataList = this.debounce(function () {
      this.getTaxesList()
    }, 500)
  },
  watch: {},
  methods: {
    deleteItemTax (taxId) {
        let fIndex = this.itemDetail.taxRateList.findIndex(x => x.taxId === taxId)
        let fIndex2 = this.selectedTaxList.findIndex(x => x.taxId === taxId)
        
        this.finalTaxList = []
          if (fIndex >= 0) {
              this.itemDetail.taxRateList.splice(fIndex,1)
              this.taxesAmmount = 0
              this.calculateTaxes(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
          }
          if (fIndex2 >= 0) {
              this.selectedTaxList.splice(fIndex,1)
          }
    },
    selectNewTaxes (list) {
      this.selectedTaxList = list
      this.itemDetail.taxRateList = list
      this.finalTaxList = []
      this.taxesAmmount = 0
      
      if (this.selectedTaxList.length > 0) {
        this.calculateTaxes(this.selectedTaxList, this.itemDetail.itemAmount)
      }
    },
    setItemPrice (value) {
        this.itemDetail.itemPrice = value === '' ? 0 : Number(value) 
        this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty

        if (this.itemDetail.taxRateList.length > 0) {
            this.calculateTaxesEdit(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
        }
    },
    setItemAmount (value) {
        this.itemDetail.itemAmount = value === '' ? 0 : Number(value) 
        this.itemDetail.itemPrice = this.itemDetail.itemAmount / this.itemDetail.itemQty
        // this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty

        if (this.itemDetail.taxRateList.length > 0) {
            this.calculateTaxesEdit(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
        }
    },
    discard () {
      this.$root.$emit('itemSelectDetailHandler', false, null)
      document.body.style = 'overflow: visible;'
    },
    saveItems () {
        let valid = true
        if (this.itemDetail.isTaxable) {
            if (this.itemDetail.taxRateList.length < 1) {
                valid = false
                this.taxlistErr = true
            }
        }
        if (valid) {
            this.$root.$emit('itemSelectDetailHandler', true, this.itemDetail)
            document.body.style = 'overflow: visible;'
        }
    },
    minusQnty () {
        this.itemDetail.itemQty = this.itemDetail.itemQty - 1
        this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty

        if (this.itemDetail.taxRateList.length > 0) {
            this.calculateTaxesEdit(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
        }
    },
    addQnty () {
        this.itemDetail.itemQty += 1
        this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty

        if (this.itemDetail.taxRateList.length > 0) {
            this.calculateTaxesEdit(this.itemDetail.taxRateList, this.itemDetail.itemAmount)
        }
    },
    removeTaxeble () {
        if (!this.itemDetail.isTaxable) {
            this.itemDetail.taxRateList = []
            this.finalTaxList = []
            this.selectedTaxList = []
            this.taxesAmmount = 0
            this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty
        } else {
            this.addTaxinput = true;
        }
    },
    calculateTaxesEdit (list, itemAmount) {
        
        this.taxesAmmount = 0
       list.map((rate) => {
          let itemTaxamount = itemAmount * rate.taxValue / 100
          this.taxesAmmount += itemTaxamount
          let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
          if (fIndex >= 0) {
              this.finalTaxList[fIndex].ammount = itemTaxamount
          } else {
              let obj = {
                  taxName: rate.taxName,
                  taxValue: rate.taxValue,
                  ammount: itemTaxamount,
                  taxId: rate.taxId
              }
              this.finalTaxList.push(obj)
          }
      })
    },
    calculateTaxes (list, itemAmount) {
        this.taxlistErr = false
       list.map((rate) => {
          let itemTaxamount = itemAmount * rate.taxValue / 100
          let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
          if (fIndex >= 0) {
              this.finalTaxList[fIndex].ammount += itemTaxamount
          } else {
              let obj = {
                  taxName: rate.taxName,
                  taxValue: rate.taxValue,
                  ammount: itemTaxamount,
                  taxId: rate.taxId
              }
              this.finalTaxList.push(obj)
          }
      })
      this.finalTaxList.map(data=> {
            this.taxesAmmount += data.ammount
        })
    },
    addNewTax () {
        this.addNewItemTax = true

    },
    searchTaxes  (searchText) {
      this.searchForItem = searchText
      this.getTaxesDataList ()
    },
    getTaxesList () {
        MyJobApp.getTaxList(
        0,
        20,
        this.searchForItem,
        'DESC',
        'taxName',
        response => {
          let templist = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map((data) => {
                templist.push({...data, displayText: data.taxName + ' ' +  `(${data.taxValue}%)`})
            }) 
          }
          this.itemtaxList = templist
        },
        error => {
          console.error(error)
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('addNewTaxHandler')
    this.$root.$off('confirmBoxHandler')
  }
}
</script>
<style scoped>

</style>