<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: visible; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span >Update Job</span>
            </div>
          </div>
          <div class="p-4">
            <div class=" mb-4">
            <TextField
            :inputId="'Instructionid'"
            :inputext="instruction"
            :placholderText="`Job Title`"
            :lableText="`Job Title`"
            :inputType="'text'"
            :autoFocus="true"
            :fieldError="titleErr !== ''"
            @keyPressAction="titleErr = ''"
            :textMaxlength="100"
            :showcharLimit="true"
            :setReadOnly="false"
            @inputChangeAction="(data) => instruction = data"  />
           <p v-if="titleErr !== ''" class="text-error heading-7">{{titleErr}}</p>
            </div>
            <div class="">
                  <Dropdown 
                  :inputext="searchForCustomer" 
                  :inputId="'searchwp-Customer'"
                  :lableText="'Customer'"
                  :placholderText="`Customer`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showClearIcon="true"
                  :showPlus="true"
                  :keyName="'companyName'"
                  :listId="'customerlistid'"
                  :items="customersList"
                  @inputChangeAction="callsearchCust"
                  @addNew="addNewCustomer"
                  @selectNewForVal="selectNewCustomer"
                  />
            </div>
            <div class="mt-4">
              <div class="flex items-center py-1">
                 <CheckBoxInput :checkValue="isVipJob" :label="`VIP Job`" :size="'small'" :disabled="false" @setIsActive="isVipJob = !isVipJob"/>
                  </div>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNote()"/>
            </div>
            </div>
        </div>
    </div>
    <AddCustomer v-if="addCustomer" />
  </div>
</template>
<script>
import Dropdown from '@/View/components/dropdown.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
import deboucneMixin from '@/mixins/debounce.js'
import CheckBoxInput from '../../components/checkBoxInput.vue'
export default {
  name: 'addUpdatenote',
  components: {
    TextField,
    Dropdown,
    AddCustomer,
    Button,
    CheckBoxInput,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      isBillable: false,
      isVipJob: false,
      addCustomer: false,
      customerId: 0,
      instruction: '',
      customersList: [],
      searchForCustomer: '',
      searchApitext: '', 
      getCustList: () => {},
      titleErr: ''
    }
  },
  props: ['detail', 'reqType'],
   created() {
  },
  mounted () {
    this.customerId = this.detail.customerId
    this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
    this.$root.$on("addCustomerHandler", (response, data) => {
      console.log(response);
      if (response) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.getCustomerList()
    this.instruction = this.detail.jobTitle
    this.isVipJob = this.detail.isVipJob
    document.body.style = 'overflow: hidden;'
  },
  watch: {
    detail: {
      handler () {
        this.instruction = this.detail.jobTitle
        this.isVipJob = this.detail.isVipJob
      }, deep: true
    }
  },
  methods: {
    addNewCustomer () {
      this.addCustomer = true
    },
    callsearchCust (text) {
      this.searchApitext = text
      this.getCustList()
    },
    saveNote () {
        let isValid = true
        let reqObj = null
          if (this.instruction.trim() === '') {
              isValid = false
              this.titleErr = 'Job Title in required'
          }
        reqObj =  {
          jobId: parseInt(this.$route.params.jobId),
          jobTitle: this.instruction,
          customerId: this.customerId,
          isVipJob: this.isVipJob,
        }
       if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
       MyJobApp.updateJobDetail(
        [],
        'jobTitle',
         reqObj,
        '',
        response => {
          document.body.style = 'overflow: visible;'
          this.$root.$emit('updateJobHandler', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
       }
    },
    getCustomerList () {
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        'customer',
        response => {
          this.customersList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.customersList.map(data => {
            if (this.customerId === data.customerId) {
              this.searchForCustomer = data.companyName
              this.customerId = data.customerId
            }
          })
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    selectNewCustomer (data) {
        if (data !== null) {
            this.searchForCustomer = data.companyName
            this.customerId = data.customerId
        } else {
            this.searchForCustomer = ''
            this.customerId = 0
        }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('updateJobHandler', false)
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>
.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: fit-content;
    /* width: 750px; */
    justify-content: center;
    text-align: center;
}

.dropzone-container {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 20px;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
}

.preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}


.progress-bar {
  height: 5px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}
 .progressDiv {
    border: grey solid 1px !important;
    padding: 5px;
    font-size: small;
    position: relative;
}
.progressCancelButton {
    position: absolute;
    top:10px;
    right:1px;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>