<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 800px;max-height: 85%;">
    <div class="relative bg-screen_bg">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
            Check List
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <!-- <div class="px-4 pt-4 flex items-center justify-between"> -->
          <!-- <div class="w-72">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search...`"/>
          </div> -->
          <!-- <div class="text-primary heading-4 cursor-pointer" @click="addNewCheckList = true">
            <p>+ Add New</p>
          </div> -->
        <!-- </div> -->
        <div v-if="itemsDataList.length > 0" class="p-4">
          <div v-for="(item, i) in itemsDataList" :key="i + 'i'" class="card rounded-xl bg-white mb-2 p-4">
            <div class="flex items-center ">
              <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(item, i)" v-model="item.isSelected" >
              <span class="pl-4 text-text2 heading-4 cursor-pointer" @click="checkboxCheck(item, i)">{{item.checkListTitle}}</span>
            </div>
          </div>
        </div>
        <div v-else class="px-4 pb-4">
          <NoRecords :alertMessage="`No records found.`" />
        </div>
      </div>
        <div class=" sticky bottom-1 bg-screen_bg w-full flex justify-end pr-3 pt-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItems()"/>
          </div>
          </div>
      </div>
  </div>
  <addEditCheckListItem v-if="addNewCheckList" :selectedItemId="0"></addEditCheckListItem>
</div>
</template>
<script>
import addEditCheckListItem from '@/View/setting/checkList/list/addEditCheckListItem.vue'
import Button from '@/View/components/globalButton.vue'
// import SearchTextInput from '@/View/components/searchTextInput.vue'
import NoRecords from '@/View/components/noRecords.vue'
// import TextField from '@/View/components/textfield.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import deboucneMixin from '@/mixins/debounce.js'
import MyJobApp from '@/api/MyJobApp.js'
export default {
name: 'sendEmail',
components: {
  NoRecords,
  Button,
  // SearchTextInput,
  addEditCheckListItem
},
mixins: [deboucneMixin],
data () {
  return {
    addNewCheckList: false,
    selectedItemList: [],
    itemsDataList: [],
    searchItemText: '',
    dialogHeight: '',
    getItems: () => {},
    sendArray: [],
  }
},
props: ["itemsSelected"],
 created() {
  this.selectedItemList = JSON.parse(JSON.stringify(this.itemsSelected)) 
},
mounted () {
  document.body.style = 'overflow: hidden;'
  this.itemListGet()
  this.getItems = this.debounce(function () {
    this.itemListGet()
  }, 500)
  this.$root.$on("closeaddEditItems", (response) => {
    console.log(response);
    if (response) {
      this.itemListGet()
      this.addNewCheckList = false
    }
    this.addNewCheckList = false
  });
  this.dialogHeight = document.getElementById('itemdialogs').offsetHeight;
},
watch: {
  sendArray: {
    handler () {
      console.log('sendArray', this.sendArray)
    },deep: true
  },
},
methods: {
  checkboxCheck (data) {
    data.isSelected = !data.isSelected
      this.sendArray = []
      for (let index = 0; index < this.itemsDataList.length; index++) {
          if (this.itemsDataList[index].isSelected) {
        this.sendArray.push(this.itemsDataList[index])
        }
      }
    // if (this.searchItemText === '') {
    //   this.sendArray = []
    //   for (let index = 0; index < this.itemsDataList.length; index++) {
    //       if (this.itemsDataList[index].isSelected) {
    //     this.sendArray.push(this.itemsDataList[index])
    //     }
    //   }
    // } else {
    //   console.log('data.isSelected', this.sendArray.length)
    //   let fIndex = this.sendArray.findIndex(x => x.checkListItemMasterId === data.checkListItemMasterId)
    //   if (fIndex >= 0) {
    //     this.sendArray.splice(fIndex, 1)
    //   } else {
    //     this.sendArray.push(data)
    //   }
    // }
  },
   searchCall (data) {
    this.searchItemText = data
    this.getItems()
  },
  discard () {
    this.$root.$emit('addEditCheckList', false, [])
  },
  saveItems () {
    this.$root.$emit('addEditCheckList', true, this.sendArray)
  },
  itemListGet () {
    MyJobApp.SettingCheckListItemList(
      0,
      50,
      this.searchItemText,
      '',
      'DESC',
      true,
      0,
      response => {
        let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
        tempList.forEach(element => {
          element.isOldSelected = false
          element.isSelected = false
        });
        setTimeout(() => {
          if (this.selectedItemList.length > 0 && tempList.length > 0) {
          this.selectedItemList.map(element => {
            let fIndex = tempList.findIndex(x => x.checkListItemMasterId === element.checkListItemMasterId)
              if (fIndex >= 0) {
                tempList[fIndex].isSelected = true
                tempList[fIndex].isOldSelected = true
              }
            });
          }
          if (this.sendArray.length > 0 && tempList.length > 0) {
          this.sendArray.map(element => {
            let fIndex = tempList.findIndex(x => x.checkListItemMasterId === element.checkListItemMasterId)
              if (fIndex >= 0) {
                tempList[fIndex].isSelected = true
                tempList[fIndex].isOldSelected = true
              }
            });
          }
        }, 50);
        this.itemsDataList = tempList
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  }
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>

</style>