import { render, staticRenderFns } from "./VisitList.vue?vue&type=template&id=351d835b&scoped=true&"
import script from "./VisitList.vue?vue&type=script&lang=js&"
export * from "./VisitList.vue?vue&type=script&lang=js&"
import style0 from "./VisitList.vue?vue&type=style&index=0&id=351d835b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351d835b",
  null
  
)

export default component.exports