<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 1000px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div class="">
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                Schedule Visit
            </div>
          </div>
          <div class="p-4 pb-0">
            <SheduleVisit :isFromSchedule="false" :title="'Schedule'" :showEmployeeSelection="true" :showJobItemSelection="true" :type="'oneVisit'" :visitErr="visitErr" :jobDetail="detail" />
          </div>
          <div class="p-4 pt-0">
            <div class="heading-5">
              <span v-if="visitData.checkList.length === 0" @click="selectInvItems()" class="text-primary font-bold cursor-pointer">+ Add Check</span>
              <span v-if="visitData.checkList.length > 0" @click="selectInvItems()" class="text-primary font-bold cursor-pointer">+ Add More Check</span>
            </div>
            <div v-for="(checks, index) in visitData.checkList" :key="index">
              <li class="text-gray4 py-1">{{checks.checkListTitle}}</li>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="addNewVisit()"/>
            </div>
            </div>
        </div>
    </div>
    <AddEditItems v-if="addItems" :itemsSelected="visitData.checkList"></AddEditItems>
  </div>
</template>
<script>
import AddEditItems from '@/View/job/visit/components/AddEditItems.vue'
import Button from '@/View/components/globalButton.vue'
import SheduleVisit from '@/View/job/components/SheduleVisit.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    AddEditItems,
    Button,
    SheduleVisit,
  },
  data () {
    return {
      visitErr: false,
      addItems: false,
        visitData: {
            jobId: 0,
            visitStartDateTime: '',
            visitEndDateTime: '',
            assignToList: [],
            itemList: [],
            checkList: [],
        },
    }
  },
  props: ["detail"],
   created() {
  },
  mounted () {
    this.visitData.jobId = parseInt(this.$route.params.jobId),
    document.body.style = 'overflow: hidden;'
    this.$root.$on('setItemSelectedHandler', (response) => {
      this.visitData.itemList = response
    })
    this.$root.$on("addEditCheckList", (data, array) => {
      document.body.style = 'overflow: visible;'
      if (data && array.length > 0) {
        this.visitData.checkList = JSON.parse(JSON.stringify(array))
      }
      this.addItems = false
    });
    this.$root.$on('finalvisitlistHandler', (response) => {
      this.visitData.visitStartDateTime = response[0].visitStartDateTime
      this.visitData.visitEndDateTime = response[0].visitEndDateTime
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.visitData.assignToList = response
    })
  },
  beforeDestroy () {
    this.$root.$off("addEditCheckList");
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setSelectedEmpHandler");
  },
  watch: {},
  methods: {
    selectInvItems () {
      this.addItems = true
    },
    addNewVisit () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.addJobVisit(
          this.visitData,
          (response) => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('addNewVisitHandler', true)
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addNewVisitHandler', false)
    },
  },
}
</script>
<style scoped>

</style>