<template>
  <div class="popup_overlay">
    <div class="custom_dialog" :style="`width: 100%; overflow: auto; height: 100vh`">
      <div class="relative">
        <div :style="`width: 100%;height:` + imageDivHeight2 + `px;overflow: auto`">
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              Send Email
            </div>
          </div>
          <div class="">
            <div>
              <div class="flex justify-end mt-4 pr-4">
                <p class="ma-0 pa-0 text-primary text-right">
                  <span class="mx-1 cursor-pointer" @click="showCCInput = !showCCInput">+CC</span>
                  <span class="mx-1 cursor-pointer" @click="showBCCInput = !showBCCInput">+BCC</span>
                </p>
              </div>
              <div class="w-full">
                <div v-if="isShowFromEmail" v-click-outside="closeToFEList" class="px-2 relative">
                  <div
                    :class="`py-2 p-3 flex ${displayFromTagSelects ? 'border-b border-primary' : 'border-b border-gray2'}`"
                    @click.stop.passive="displayFromTagSelects = true">
                    <div class="flex flex-auto flex-wrap">
                      <div class="flex flex-wrap min-w-full">
                        <span class="mt-2 mr-2">From:</span>
                        <input readonly autocomplete="off" @focus="displayFromTagSelects = true; backSpacecountfr = 0"
                         v-model="fromEmail" 
                         @keyup="keyUpfr()"
                          v-on:keyup.enter="onEnterfr"
                          @blur="scrollCountFr = 0; downPressCountFr = null" @keydown="checInputTabfr()"
                           type="text" id="searchFemailFTagId" 
                          class=" h-9 focus:outline-none focus:ring-blue-500 focus:border-blue-500 heading-4 cursor-pointer">
                          <span class="mt-2 heading-6 text-error" v-if="FromError !== ''">{{ FromError }}</span>
                          <span class="mt-2 heading-6 text-error" v-if="fromEMailList.length < 1">Please add email configuration first

                            <span class="text-primary cursor-pointer" @click="$router.push({name: 'EmailconfigurationAdd', params: {emailId: 0}})"> Add Email Configuration</span>
                          </span>
                      </div>
                    </div>
                    <span class=" mt-2 right-6 ">
                      <p v-if="displayFromTagSelects"><i class="fas fa-chevron-up text-gray3 heading-5"></i></p>
                      <span v-if="!displayFromTagSelects"><i class="fas fa-chevron-down text-gray3 heading-5"></i></span>
                    </span>
                  </div>
                  <div id="fremailLIst" v-if="displayFromTagSelects"
                    class="absolute z-10 flex-col  max-h-64 h-auto bg-gray1 overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg"
                    style="overflow-x: auto; width: 99%">
                    <div v-for="(data, index) in fromEMailList" :key="index"
                      @click.stop.prevent="selectFemail(data)"
                      :class="`${downPressCountfr !== null ? downPressCountfr === index ? 'bg-info text-primary' : '' : fromEmail === data.emailId ? 'bg-info text-primary' : '' } flex shadow hover:bg-info items-center  cursor-pointer p-3 pl-2  border-b border-transparent border-l-2 relative hover:border-teal-100`">
                      {{ data.emailId }}</div>
                  </div>
                </div>
                <div v-click-outside="closeToEList" class="px-2 relative">
                  <div
                    :class="`py-2 p-3 flex ${displayTagSelects ? 'border-b border-primary' : 'border-b border-gray2'}`"
                    @click="showDropdownList('users')">
                    <div class="flex flex-auto flex-wrap">
                      <div class="flex flex-wrap min-w-full">
                        <span class="mt-2 mr-2">To:</span>
                        <div v-for="(data, index) in toemailList" :key="index" class="">
                          <div
                            :class="`flex items-center text-xs mt-2 mx-1 ml-0 py-1 px-2 rounded-full border border-teal-300  ${data.validEmail === 'invalid' ? 'bg-error text-white' : 'bg-gray1 text-text1'} font-bold`">
                            {{ data.name }} {{ `(${data.email})` }}
                            <div class="bg-gray4 ml-1 p-px rounded-full  text-white max-w-max items-center"
                              @click.stop.prevent="selectTags(data, index, 'remove')">
                              <span class="p-1 cursor-pointer"><i class="fa-solid fa-xmark"></i></span>
                            </div>
                          </div>
                        </div>
                        <input autofocus autocomplete="off" @focus="showDropdownList('users')" @keyup="keyUp('toemail')"
                          v-on:keyup.enter="onEnter" v-model="searchForToEmail"
                          @blur="scrollCount = 0; downPressCount = null" @keydown="checInputTab('toemail')"
                          @input="emailErrorObj.sendToErr = ''" type="text" id="searchemailTagId"
                          class=" h-9 focus:outline-none focus:ring-blue-500 focus:border-blue-500 heading-4">
                        <span class="mt-2 heading-6 text-error"
                          v-if="emailErrorObj.sendToErr !== ''">{{ emailErrorObj.sendToErr }}</span>
                      </div>
                    </div>
                    <span class=" mt-2 right-6 ">
                      <p v-if="displayTagSelects"><i class="fas fa-chevron-up text-gray3 heading-5"></i></p>
                      <span v-if="!displayTagSelects"><i class="fas fa-chevron-down text-gray3 heading-5"></i></span>
                    </span>
                  </div>
                  <div id="toemailLIst" v-if="displayTagSelects"
                    class="absolute z-10 flex-col  max-h-64 h-auto bg-gray1 overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg"
                    style="overflow-x: auto; width: 99%">
                    <div v-for="(data, index) in toEmailDropDownList" :key="index"
                      @click.stop.prevent="selectTags(data, index, 'select')"
                      :class="`${downPressCount === index ? 'bg-info text-primary' : ''} flex shadow hover:bg-info items-center  cursor-pointer p-3 pl-2  border-b border-transparent border-l-2 relative hover:border-teal-100`">
                      {{ data.name }} {{ `( ${data.email} )` }}
                    </div>
                    <div v-if="searchForToEmail"
                      :class="`flex shadow hover:bg-info items-center  cursor-pointer p-3 pl-2  border-b border-transparent border-l-2 relative hover:border-teal-100`">
                      <span class="heading-6 text-gray3 mr-2">Press Enter to select</span>
                      <span class=" border border-gray3 rounded-full px-2 py-px">{{ searchForToEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showCCInput" class="w-full">
                <div v-click-outside="closeCCEList" class="px-2 relative">
                  <div
                    :class="`py-2 p-3 flex  ${displayCCSelects ? 'border-b border-primary' : 'border-b border-gray2'}`"
                    @click="showCCDropdownList('users')">
                    <div class="flex flex-auto flex-wrap">
                      <div class="flex flex-wrap min-w-full">

                        <span class="mt-2 mr-2">CC:</span>
                        <div v-for="(data, index) in ccemailList" :key="index" class="">
                          <div
                            :class="`flex items-center text-xs mt-2 mx-1 ml-0 py-1 px-2 rounded-full border border-teal-300  ${data.validEmail === 'invalid' ? 'bg-error text-white' : 'bg-gray1 text-text1'} font-bold`">
                            {{ data.name }} {{ `(${data.email})` }}
                            <div class="bg-gray4 ml-1 p-px rounded-full  text-white max-w-max items-center"
                              @click.stop.prevent="selectCCTags(data, index, 'remove')">
                              <!-- <i style="font-size: 18px;" class="fas fa-times-circle cursor-pointer" ></i> -->
                              <span class="p-1 cursor-pointer"><i class="fa-solid fa-xmark"></i></span>
                            </div>
                          </div>
                        </div>
                        <input autofocus autocomplete="off" @focus="showCCDropdownList('users')" @keyup="keyUp('ccemail')"
                          @keydown="checInputTab('ccemail')" @blur="scrollCount = 0; downPressCount = null"
                          v-on:keyup.enter="onCCEnter" @input="emailErrorObj.SendccErr = ''" v-model="searchForCCEmail"
                          type="text" id="searchccemailTagId"
                          class=" h-9 focus:outline-none focus:ring-blue-500 focus:border-blue-500 heading-4">
                        <span class="mt-2 heading-6 text-error"
                          v-if="emailErrorObj.SendccErr !== ''">{{ emailErrorObj.SendccErr }}</span>
                      </div>
                    </div>
                    <span class=" mt-2 right-6 ">
                      <p v-if="displayCCSelects"><i class="fas fa-chevron-up text-gray3 heading-5"></i></p>
                      <span v-if="!displayCCSelects"><i class="fas fa-chevron-down text-gray3 heading-5"></i></span>
                    </span>
                  </div>
                  <div id="ccemailLIst" v-if="displayCCSelects"
                    class="absolute z-10 flex-col w-full max-h-64 h-auto bg-gray1 overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg"
                    style="overflow-x: auto;">
                    <div v-for="(data, index) in ccEmailDropDownList" :key="index"
                      @click="selectCCTags(data, index, 'select')"
                      :class="`${downPressCount === index ? 'bg-info text-primary' : ''} flex shadow hover:bg-info items-center  cursor-pointer p-3 pl-2  border-b border-transparent border-l-2 relative hover:border-teal-100`">
                      {{ data.name }} {{ `( ${data.email} )` }}
                    </div>
                    <div v-if="searchForCCEmail"
                      class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100">
                      <span class="heading-6 text-gray3 mr-2">Press Enter to select</span>
                      <span class=" border border-gray3 rounded-full px-2 py-px">{{ searchForCCEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showBCCInput">
                <div v-click-outside="closeBccEList" class="px-2 relative">
                  <div
                    :class="`py-2 p-3 flex  ${displayBccSelects ? 'border-b border-primary' : 'border-b border-gray2'}`"
                    @click="showBccDropdownList('users')">
                    <div class="flex flex-auto flex-wrap">
                      <div class="flex flex-wrap min-w-full">

                        <span class="mt-2 mr-2">BCC:</span>
                        <div v-for="(data, index) in bccEmaislList" :key="index" class="">
                          <div
                            :class="`flex items-center text-xs mt-2 mx-1 ml-0 py-1 px-2 rounded-full border border-teal-300  ${data.validEmail === 'invalid' ? 'bg-error text-white' : 'bg-gray1 text-text1'} font-bold`">
                            {{ data.name }} {{ `(${data.email})` }}
                            <div class="bg-gray4 ml-1 p-px rounded-full  text-white max-w-max items-center"
                              @click.stop.prevent="selectBccTags(data, index, 'remove')">
                              <!-- <i style="font-size: 18px;" class="fas fa-times-circle cursor-pointer" ></i> -->
                              <span class="p-1 cursor-pointer"><i class="fa-solid fa-xmark"></i></span>
                            </div>
                          </div>
                        </div>
                        <input autofocus autocomplete="off" @focus="showBccDropdownList('users')"
                          @keyup="keyUp('bccemail')" @blur="scrollCount = 0; downPressCount = null"
                          @keydown="checInputTab('bccemail')" v-on:keyup.enter="onBccEnter"
                          @input="emailErrorObj.SendBccErr = ''" v-model="searchForBccEmail" type="text"
                          id="searchBccemailTagId"
                          class=" h-9 focus:outline-none focus:ring-blue-500 focus:border-blue-500 heading-4">
                        <span class="mt-2 heading-6 text-error"
                          v-if="emailErrorObj.SendBccErr !== ''">{{ emailErrorObj.SendBccErr }}</span>
                      </div>
                    </div>
                    <span class=" mt-2 right-6 ">
                      <p v-if="displayBccSelects"><i class="fas fa-chevron-up text-gray3 heading-5"></i></p>
                      <span v-if="!displayBccSelects"><i class="fas fa-chevron-down text-gray3 heading-5"></i></span>
                    </span>
                  </div>
                  <div id="bccemailLIst" v-if="displayBccSelects"
                    class="absolute z-10 flex-col w-full max-h-64 h-auto bg-gray1 overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg"
                    style="overflow-x: auto;">
                    <div v-for="(data, index) in emailDropDownListBcc" :key="index"
                      @click="selectBccTags(data, index, 'select')"
                      :class="`${downPressCount === index ? 'bg-info text-primary' : ''} flex shadow hover:bg-info items-center  cursor-pointer p-3 pl-2  border-b border-transparent border-l-2 relative hover:border-teal-100`">
                      {{ data.name }} {{ `( ${data.email} )` }}
                    </div>
                    <div v-if="searchForBccEmail"
                      class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100">
                      <span class="heading-6 text-gray3 mr-2">Press Enter to select</span>
                      <span class=" border border-gray3 rounded-full px-2 py-px">{{ searchForBccEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 mt-4">
              <p class="mb-2">Message:</p>
              <Editor v-model="editorText" v-if="!isDefault" :error="emailErrorObj.messageErr" />
              <div v-if="isDefault" class="pl-2 border border-gray2 rounded-xl p-2">
                <p v-html="editorText"></p>
              </div>
              <p v-if="emailErrorObj.messageErr" class="text-error heading-6 py-2">
                Email message is required
              </p>
            </div>
            <div v-if="isDefault" class="p-2">
              <div class="text-error heading-5 font-semibold">*If you want to change the Email content, you will have to create Email SMTP configuration by <span @click="routingTodata()" class="text-primary hover:underline cursor-pointer">clicking here</span></div>
            </div>
          </div>
        </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3 pt-4">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'"
              @buttonAction="discard(false)" />
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Send Email'"
              @buttonAction="sendEmail(true)" />
          </div>
        </div>
      </div>
    </div>
    <ConfirmBox v-if="isEmailFormat" :warning="true"
      :message="'One or more email(s) are invalid, please check and rectify email(s) in red color.'" :title="'Error'" />
  </div>
</template>
<script>
import { validateEmail } from "@/utils/validation.js";
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import Editor from '@/View/components/editor.vue'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Vue from 'vue'
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  name: 'sendEmail',
  components: {
    Button,
    ConfirmBox,
    Editor,
  },
  data() {
    return {
      isDefault: false,
      isShowFromEmail: true,
      imageDivHeight: window.innerHeight,
      imageDivHeight2: window.innerHeight - 70,
      scrollCount: null,
      downPressCount: null,
      backSpacecount: 0,
      contentWidth: 0,
      toemailList: [],
      ccemailList: [],
      bccEmaislList: [],
      toEmailMainList: [],
      ccEmailMainList: [],
      bccEmailsMainList: [],
      toEmailDropDownList: [],
      ccEmailDropDownList: [],
      emailDropDownListBcc: [],
      displayTagSelects: false,
      displayFromTagSelects: false,
      displayCCSelects: false,
      isEmailFormat: false,
      showEmailSaveBtn: true,
      displayBccSelects: false,
      messageSectionShow: false,
      showCCInput: false,
      showBCCInput: false,
      errorMsg: '',
      searchForToEmail: '',
      searchForCCEmail: '',
      searchForBccEmail: '',
      editorText: '',
      emailErrorObj: {
        sendToErr: '',
        SendccErr: '',
        SendBccErr: '',
        messageErr: false
      },
      fromEmail: '',
      fromName: '',
      subject: '',
      fromEMailList: [],
      totalCount: 0,
      FromError: '',
       scrollCountfr: null,
      downPressCountfr: null,
      backSpacecountfr: 0,
    }
  },
  props: ['entitySlug', 'detailId', 'customerId'],
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.getEmilConfList()
    this.searchContEmail()
    if (window.innerWidth < 900) {
      this.contentWidth = window.innerWidth - 150
    }
    this.$root.$on('confirmBoxHandler', () => {
      document.body.style = 'overflow: visible;'
      this.isEmailFormat = false
    })
  },
  beforeDestroy() {
    this.$root.$off('confirmBoxHandler')
  },
  watch: {
    displayFromTagSelects: {
      handler() {
        if (this.displayFromTagSelects) {
          this.displayTagSelects = false
          this.displayCCSelects = false
          this.displayBccSelects = false
        }
      }
    },
    showCCInput: {
      handler() {
        if (this.showCCInput) {
          setTimeout(() => {
            document.getElementById('searchccemailTagId').focus()
          }, 500);
        }
      }
    },
    showBCCInput: {
      handler() {
        if (this.showBCCInput) {
          setTimeout(() => {
            document.getElementById('searchBccemailTagId').focus()
          }, 500);
        }
      }
    },
    editorText: {
      handler() {
        if (this.editorText !== '') {
          this.emailErrorObj.messageErr = false
        }
      }
    },
    searchForToEmail: {
      handler() {
        this.backSpacecount = 0
        let filter = this.searchForToEmail.toLowerCase();
        this.toEmailDropDownList = []
        for (let index = 0; index < this.toEmailMainList.length; index++) {
          if (this.searchForToEmail.toLowerCase().indexOf(filter) > -1 === this.toEmailMainList[index].name.toLowerCase().indexOf(filter) > -1) {
            this.toEmailDropDownList.push(this.toEmailMainList[index])
          }
        }
        this.toemailList.forEach(element => {
          this.toEmailDropDownList.forEach((data, i) => {
            if (element.email === data.email) {
              this.toEmailDropDownList.splice(i, 1)
            }
          })
        })
      }
    },
    searchForCCEmail: {
      handler() {
        this.backSpacecount = 0
        let filter = this.searchForCCEmail.toLowerCase();
        this.ccEmailDropDownList = []
        for (let index = 0; index < this.ccEmailMainList.length; index++) {
          if (this.searchForCCEmail.toLowerCase().indexOf(filter) > -1 === this.ccEmailMainList[index].name.toLowerCase().indexOf(filter) > -1) {
            this.ccEmailDropDownList.push(this.ccEmailMainList[index])
          }
        }
        this.ccemailList.forEach(element => {
          this.ccEmailDropDownList.forEach((data, i) => {
            if (element.email === data.email) {
              this.ccEmailDropDownList.splice(i, 1)
            }
          })
        })
      }
    },
    searchForBccEmail: {
      handler() {
        if (this.searchForBccEmail !== '') {
          this.backSpacecount = 0
        }
        let filter = this.searchForBccEmail.toLowerCase();
        this.emailDropDownListBcc = []
        for (let index = 0; index < this.bccEmailsMainList.length; index++) {
          if (this.searchForBccEmail.toLowerCase().indexOf(filter) > -1 === this.bccEmailsMainList[index].name.toLowerCase().indexOf(filter) > -1) {
            this.emailDropDownListBcc.push(this.bccEmailsMainList[index])
          }
        }
        this.bccEmaislList.forEach(element => {
          this.emailDropDownListBcc.forEach((data, i) => {
            if (element.email === data.email) {
              this.emailDropDownListBcc.splice(i, 1)
            }
          })
        })
      }
    },
  },
  methods: {
    routingTodata () {
      let url = this.$router.resolve({name: 'Emailconfiguration'})
      window.open(url.href, '_blank')
    },
    searchContEmail() {
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      MyJobApp.searchContactEmail(
        1000,
        '',
        this.customerId,
        response => {
          if (response.Data !== null) {
            response.Data.map(data => {
              if (data.emailAddress) {
                let obj = {
                  email: data.emailAddress,
                  name: data.CompanyName,
                }
                if (this.customerId === data.CustomerId) {
                  this.toemailList.push({
                    email: data.emailAddress,
                    name: data.CompanyName,
                    validEmail: 'valid'
                  })
                } else {
                this.toEmailMainList.push(obj)
                this.ccEmailMainList.push(obj)
                this.bccEmailsMainList.push(obj)
                this.toEmailDropDownList.push(obj)
                this.ccEmailDropDownList.push(obj)
                this.emailDropDownListBcc.push(obj)
                }
                
              }
            })

          }

          this.$store.dispatch('SetLoader', { status: false, message: '' })
        },
        () => {
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }
      )
    },
    discard(data) {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('bookingConfirmationHandler', data)
    },
    getDefaultBodyData() {
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading Required Data....' })
      MyJobApp.getSendBookConfData(
        this.entitySlug,
        this.detailId,
        response => {
          this.editorText = this.styleLinksInHtml(response.Data.defaultEmailBodyMessage);
          // this.editorText = response.Data.defaultEmailBodyMessage
          // this.fromEmail = response.Data.fromEmail
          this.fromName = response.Data.fromName
          this.subject = response.Data.emailSubject
          if (this.fromEMailList.length === 0) {
            this.isDefault = true
            this.fromEmail = response.Data.fromEmail
          } else {
            this.isDefault = false
          }
          setTimeout(() => {
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }, 300);
        },
        error => {
          this.$store.dispatch('SetLoader', { status: false, message: '' })
          this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
        }
      )
    },
    styleLinksInHtml(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const links = doc.querySelectorAll('a');
      links.forEach(link => {
        link.style.cursor = 'pointer';
        link.style.color = '#1295BA';
        link.style.textDecoration = 'none';
        link.addEventListener('mouseover', () => {
          link.style.textDecoration = 'underline';
        });
        link.addEventListener('mouseout', () => {
          link.style.textDecoration = 'none';
        });
      });
      return new XMLSerializer().serializeToString(doc);
    },
    onEnter() {
      if (this.downPressCount !== null && this.toEmailDropDownList.length > 0) {
        for (let i = 0; i < this.toemailList.length; i++) {
          if (this.toemailList[i].email === this.toEmailDropDownList[this.downPressCount].email) {
            this.toemailList.splice(i, 1)
            this.searchForToEmail = ''
          }
        }
        this.toemailList.push(this.toEmailDropDownList[this.downPressCount])
        this.toEmailDropDownList.splice(this.downPressCount, 1)
        this.downPressCount = 0
        this.scrollCount = 0
      } else if (this.searchForToEmail !== '') {
        for (let i = 0; i < this.toemailList.length; i++) {
          if (this.toemailList[i].email === this.searchForToEmail.trim()) {
            this.toemailList.splice(i, 1)
            this.toemailList.push({
              email: this.searchForToEmail,
              name: this.searchForToEmail,
              validEmail: validateEmail(this.searchForToEmail) ? 'valid' : 'invalid'
            })
            return
          }
        }
        let obj = {
          email: this.searchForToEmail,
          name: this.searchForToEmail,
          validEmail: validateEmail(this.searchForToEmail) ? 'valid' : 'invalid'
        }
        this.toemailList.push(obj)
        // this.toEmailMainList.push(obj)
      }
      this.searchForToEmail = ''
    },
    onEnterfr() {
      if (this.downPressCountfr !== null && this.fromEMailList.length > 0) {
        this.fromEmail = this.fromEMailList[this.downPressCountfr].emailId
        this.downPressCountfr = null
        this.scrollCountfr = 0
        this.displayFromTagSelects = false
      }
    },
    closeToEList() {
      setTimeout(() => {
        this.displayTagSelects = false
      }, 10);
      if (this.searchForToEmail !== '') {
        this.onEnter()
      }
    },
    closeToFEList() {
        setTimeout(() => {
          this.displayFromTagSelects = false
        }, 100);
    },
    closefrEList() {
      setTimeout(() => {
        this.displayFromTagSelects = false
      }, 10);
      if (this.fromEmail !== '') {
        this.onEnterfr()
      }
    },
    closeCCEList() {
      setTimeout(() => {
        this.displayCCSelects = false
      }, 10);
      if (this.searchForCCEmail !== '') {
        this.onCCEnter()
      }
    },
    closeBccEList() {
      setTimeout(() => {
        this.displayBccSelects = false
      }, 10);
      if (this.searchForBccEmail !== '') {
        this.onBccEnter()
      }
    },
    selectTags(data, index, fromCall) {
      this.emailErrorObj.sendToErr = ''
      this.displayTagSelects = true
      if (fromCall === 'select') {
        this.toemailList.push(data)
        this.toEmailDropDownList.splice(index, 1)
      }
      if (fromCall === 'remove') {
        // this.toEmailDropDownList.push(data)
        // this.toemailList.splice(index, 1)  
        let obj = this.toemailList.splice(index, 1)
        this.toEmailDropDownList.push(obj[0])
      }
      setTimeout(() => {
        document.getElementById('searchemailTagId').focus()
      }, 10);

    },
    selectFemail(data) {
      this.fromEmail = data.emailId
      this.displayFromTagSelects = false
      this.FromError = ''
    },
    showDropdownList() {
      this.backSpacecount = 0
      setTimeout(() => {
        this.displayTagSelects = true
        document.getElementById('searchemailTagId').focus()
      }, 10);
    },
    onCCEnter() {
      if (this.downPressCount !== null && this.ccEmailDropDownList.length > 0) {
        for (let i = 0; i < this.ccemailList.length; i++) {
          if (this.ccemailList[i].email === this.ccEmailDropDownList[this.downPressCount].email) {
            this.ccemailList.splice(i, 1)
            this.searchForToEmail = ''
          }
        }
        this.ccemailList.push(this.ccEmailDropDownList[this.downPressCount])
        this.ccEmailDropDownList.splice(this.downPressCount, 1)
        this.downPressCount = 0
        this.scrollCount = 0
      } else if (this.searchForCCEmail !== '') {
        for (let i = 0; i < this.ccemailList.length; i++) {
          if (this.ccemailList[i].email === this.searchForCCEmail.trim()) {
            this.ccemailList.splice(i, 1)
            this.ccemailList.push({
              name: this.searchForCCEmail,
              email: this.searchForCCEmail,
              validEmail: validateEmail(this.searchForCCEmail) ? 'valid' : 'invalid'
            })
            this.searchForCCEmail = ''
            return
          }
        }
        let obj = {
          email: this.searchForCCEmail,
          name: this.searchForCCEmail,
          validEmail: validateEmail(this.searchForCCEmail) ? 'valid' : 'invalid'
        }
        this.ccemailList.push(obj)
      }
      this.searchForCCEmail = ''
    },
    onBccEnter() {
      if (this.downPressCount !== null && this.emailDropDownListBcc.length > 0) {
        for (let i = 0; i < this.bccEmaislList.length; i++) {
          if (this.bccEmaislList[i].email === this.emailDropDownListBcc[this.downPressCount].email) {
            this.bccEmaislList.splice(i, 1)
            this.searchForToEmail = ''
          }
        }
        this.bccEmaislList.push(this.emailDropDownListBcc[this.downPressCount])
        this.emailDropDownListBcc.splice(this.downPressCount, 1)
        this.downPressCount = 0
        this.scrollCount = 0
      } else if (this.searchForBccEmail !== '') {
        for (let i = 0; i < this.bccEmaislList.length; i++) {
          if (this.bccEmaislList[i].email === this.searchForBccEmail.trim()) {
            this.bccEmaislList.splice(i, 1)
            this.bccEmaislList.push({
              name: this.searchForBccEmail,
              email: this.searchForBccEmail,
              validEmail: validateEmail(this.searchForBccEmail) ? 'valid' : 'invalid'
            })
            this.searchForBccEmail = ''
            return
          }
        }
        let obj = {
          email: this.searchForBccEmail,
          name: this.searchForBccEmail,
          validEmail: validateEmail(this.searchForBccEmail) ? 'valid' : 'invalid'
        }
        this.bccEmaislList.push(obj)
        // this.bccEmailsMainList.push(obj)
      }
      this.searchForBccEmail = ''
    },
    selectCCTags(data, index, fromCall) {
      this.displayCCSelects = true
      if (fromCall === 'select') {
        this.ccemailList.push(data)
        this.ccEmailDropDownList.splice(index, 1)
      }
      if (fromCall === 'remove') {
        // this.ccEmailDropDownList.push(data)
        // this.ccemailList.splice(index, 1)  
        let obj = this.ccemailList.splice(index, 1)
        this.ccEmailDropDownList.push(obj[0])
      }
      setTimeout(() => {
        document.getElementById('searchccemailTagId').focus()
      }, 10);

    },
    selectBccTags(data, index, fromCall) {
      this.displayBccSelects = true
      if (fromCall === 'select') {
        this.bccEmaislList.push(data)
        this.emailDropDownListBcc.splice(index, 1)
      }
      if (fromCall === 'remove') {
        // this.emailDropDownListBcc.push(data)
        // this.bccEmaislList.splice(index, 1)  
        let obj = this.bccEmaislList.splice(index, 1)
        this.emailDropDownListBcc.push(obj[0])
      }
      setTimeout(() => {
        document.getElementById('searchBccemailTagId').focus()
      }, 10);

    },
    showCCDropdownList() {
      this.backSpacecount = 0
      setTimeout(() => {
        this.displayCCSelects = true
        document.getElementById('searchccemailTagId').focus()
      }, 10);
    },
    showBccDropdownList() {
      console.log('emailDropDownListBcc---', this.emailDropDownListBcc);
      console.log('bccEmailsMainList---', this.bccEmailsMainList);
      this.backSpacecount = 0
      setTimeout(() => {
        this.displayBccSelects = true
        document.getElementById('searchBccemailTagId').focus()
      }, 10);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 900) {
        this.contentWidth = window.innerWidth - 150
      }
    },
    sendEmail() {
      let isvalid = true
      if (this.fromEmail === '') {
        this.FromError = 'Please select from email'
        isvalid = false
      }
      if (this.toemailList.length > 0) {
        for (let r = 0; r < this.toemailList.length; r++) {
          if (!validateEmail(this.toemailList[r].email)) {
            this.isEmailFormat = true
            this.errorMsg = 'One or more email(s) are invalid, please check and rectify email(s) in red color'
            isvalid = false
            return false
          }
        }
      }
      if (this.ccemailList.length > 0 && this.showCCInput) {
        for (let r = 0; r < this.ccemailList.length; r++) {
          if (!validateEmail(this.ccemailList[r].email)) {
            this.isEmailFormat = true
            this.errorMsg = 'One or more email(s) are invalid, please check and rectify email(s) in red color'
            isvalid = false
            return false
          }
        }
      }
      if (this.bccEmaislList.length > 0 && this.showBCCInput) {
        for (let r = 0; r < this.bccEmaislList.length; r++) {
          if (!validateEmail(this.bccEmaislList[r].email)) {
            this.isEmailFormat = true
            this.errorMsg = 'One or more email(s) are invalid, please check and rectify email(s) in red color'
            isvalid = false
            return false
          }
        }
      }
      if (this.editorText === '' || this.toemailList.length === 0) {
        this.emailErrorObj.sendToErr = this.toemailList.length === 0 ? 'Please select email address or enter email address' : ''
        this.emailErrorObj.messageErr = this.editorText === '' ? true : ''
        isvalid = false
      }

      if (isvalid) {
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading....' })
        MyJobApp.emailSendBokkconf(
          this.toemailList,
          this.ccemailList,
          this.bccEmaislList,
          this.editorText,
          this.fromEmail,
          this.fromName,
          this.subject,
          this.customerId,
          this.isDefault,
          response => {
            document.body.style = 'overflow: visible;'
            this.discard(true)
            this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          },
          error => {
            this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
      }
    },
    checInputTab(fromCall) {
      if (fromCall === 'toemail') {
        let documentvalue = document.getElementById(`searchemailTagId`)
        documentvalue.onkeydown = this.checkeydownToEmail();
      }
      if (fromCall === 'ccemail') {
        let documentvalue = document.getElementById(`searchccemailTagId`)
        documentvalue.onkeydown = this.checkeydownCcEmail();
      }
      if (fromCall === 'bccemail') {
        let documentvalue = document.getElementById(`searchBccemailTagId`)
        documentvalue.onkeydown = this.checkeydownBccEmail();
      }
    },
    checInputTabfr() {
      let documentvalue = document.getElementById(`searchFemailFTagId`)
        documentvalue.onkeydown = this.checkeydownFromEmail();
    },
    downArrowAction(from) {
      if (from == 'to') {
        if (this.toEmailDropDownList.length - 1 > this.downPressCount && this.downPressCount !== null) {
          this.downPressCount = this.downPressCount + 1
          this.scrollCount = this.scrollCount + 52
          document.getElementById(`toemailLIst`).scrollTop = this.scrollCount
        }
        if (this.downPressCount === null) {
          this.downPressCount = 0

          this.scrollCount = 0
          document.getElementById(`toemailLIst`).scrollTop = this.scrollCount
        }
      }
      if (from == 'cc') {
        if (this.ccEmailDropDownList.length - 1 > this.downPressCount && this.downPressCount !== null) {
          this.downPressCount = this.downPressCount + 1
          this.scrollCount = this.scrollCount + 52
          document.getElementById(`ccemailLIst`).scrollTop = this.scrollCount
        }
        if (this.downPressCount === null) {
          this.downPressCount = 0

          this.scrollCount = 0
          document.getElementById(`ccemailLIst`).scrollTop = this.scrollCount
        }
      }
      if (from == 'bcc') {
        if (this.emailDropDownListBcc.length - 1 > this.downPressCount && this.downPressCount !== null) {
          this.downPressCount = this.downPressCount + 1
          this.scrollCount = this.scrollCount + 52
          document.getElementById(`bccemailLIst`).scrollTop = this.scrollCount
        }
        if (this.downPressCount === null) {
          this.downPressCount = 0

          this.scrollCount = 0
          document.getElementById(`bccemailLIst`).scrollTop = this.scrollCount
        }
      }
      if (from == 'fr') {
        if (this.fromEMailList.length - 1 > this.downPressCountfr && this.downPressCountfr !== null) {
          this.downPressCountfr = this.downPressCountfr + 1
          this.scrollCountfr = this.scrollCountfr + 52
          document.getElementById(`fremailLIst`).scrollTop = this.scrollCountfr
        }
        if (this.downPressCountfr === null) {
          this.downPressCountfr = 0

          this.scrollCountfr = 0
          document.getElementById(`fremailLIst`).scrollTop = this.scrollCountfr
        }
      }
    },
    upArrowAction(from) {
      if (from === 'to') {
        if (this.downPressCount > 0 && this.scrollCount > 0) {
          this.downPressCount = this.downPressCount - 1
          this.scrollCount = this.scrollCount - 52
          document.getElementById(`toemailLIst`).scrollTop = this.scrollCount !== null ? this.scrollCount : 0
        }
      }
      if (from === 'cc') {
        if (this.downPressCount > 0 && this.scrollCount > 0) {
          this.downPressCount = this.downPressCount - 1
          this.scrollCount = this.scrollCount - 52
          document.getElementById(`ccemailLIst`).scrollTop = this.scrollCount !== null ? this.scrollCount : 0
        }
      }
      if (from === 'bcc') {
        if (this.downPressCount > 0 && this.scrollCount > 0) {
          this.downPressCount = this.downPressCount - 1
          this.scrollCount = this.scrollCount - 52
          document.getElementById(`bccemailLIst`).scrollTop = this.scrollCount !== null ? this.scrollCount : 0
        }
      }
      if (from === 'fr') {
        if (this.downPressCountfr > 0 && this.scrollCountfr > 0) {
          this.downPressCountfr = this.downPressCountfr - 1
          this.scrollCountfr = this.scrollCountfr - 52
          document.getElementById(`fremailLIst`).scrollTop = this.scrollCountfr !== null ? this.scrollCountfr : 0
        }
      }

    },
    checkeydownFromEmail(e) {
      e = e || window.event;
      if (e.keyCode === 9) {
        this.closefrEList()
      }
      if (e.keyCode === 40) {
        this.downArrowAction('fr')
      }
      if (e.keyCode === 38) {
        this.upArrowAction('fr')
      }
    },
    checkeydownToEmail(e) {
      e = e || window.event;
      if (e.keyCode === 9) {
        this.closeToEList()
      }
      if (e.keyCode === 40) {
        this.downArrowAction('to')
      }
      if (e.keyCode === 38) {
        this.upArrowAction('to')
      }
    },
    checkeydownCcEmail(e) {
      e = e || window.event;
      if (e.keyCode === 9) {
        this.closeCCEList()
      }
      if (e.keyCode === 40) {
        this.downArrowAction('cc')
      }
      if (e.keyCode === 38) {
        this.upArrowAction('cc')
      }
    },
    checkeydownBccEmail(e) {
      e = e || window.event;
      if (e.keyCode === 9) {
        this.closeBccEList()
      }
      if (e.keyCode === 40) {
        this.downArrowAction('bcc')
      }
      if (e.keyCode === 38) {
        this.upArrowAction('bcc')
      }
    },
    checkKeyfrMail(e) {
      e = e || window.event;
      if (e.keyCode === 8) {
        if (this.fromEmail === '') {
          this.backSpacecountfr = this.backSpacecountfr + 1
        }
      }
    },
    checkKeyToMail(e) {
      e = e || window.event;
      if (e.keyCode === 8) {
        if (this.searchForToEmail === '') {
          this.backSpacecount = this.backSpacecount + 1
        }
        if (this.backSpacecount === 2 && this.toemailList.length > 0) {
          let data = this.toemailList.pop()
          this.toEmailDropDownList.push(data)
          for (let i = 0; i < this.toEmailMainList.length; i++) {
            if (this.toEmailMainList[i].email === data.email) {
              this.toEmailMainList.splice(i, 1)
              this.toEmailMainList.push({
                email: data.email,
                name: data.name,
              })
              return
            }
          }
          this.toEmailMainList.push(data)
        }
        this.backSpacecount = 1
      }
    },
    checkKeyCcMail(e) {
      e = e || window.event;
      if (e.keyCode === 8) {
        if (this.searchForCCEmail === '') {
          this.backSpacecount = this.backSpacecount + 1
        }
        if (this.backSpacecount === 2 && this.ccemailList.length > 0) {
          let data = this.ccemailList.pop()
          this.ccEmailDropDownList.push(data)
          for (let i = 0; i < this.ccEmailMainList.length; i++) {
            if (this.ccEmailMainList[i].email === data.email) {
              this.ccEmailMainList.splice(i, 1)
              this.ccEmailMainList.push({
                email: data.email,
                name: data.name,
              })
              return
            }
          }
          this.ccEmailMainList.push(data)
        }
        this.backSpacecount = 1
      }
    },
    checkKeyBccMail(e) {
      e = e || window.event;
      if (e.keyCode === 8) {
        if (this.searchForBccEmail === '') {
          this.backSpacecount = this.backSpacecount + 1
        }
        if (this.backSpacecount === 2 && this.bccEmaislList.length > 0) {
          let data = this.bccEmaislList.pop()
          this.emailDropDownListBcc.push(data)


          for (let i = 0; i < this.bccEmailsMainList.length; i++) {
            if (this.bccEmailsMainList[i].email === data.email) {
              this.bccEmailsMainList.splice(i, 1)
              this.bccEmailsMainList.push({
                email: data.email,
                name: data.name,
              })
              return
            }
          }
          this.bccEmailsMainList.push(data)
        }
        this.backSpacecount = 1
      }
    },
    keyUp(fromCall) {
      if (fromCall === 'toemail') {
        let documentvalue = document.getElementById(`searchemailTagId`)
        documentvalue.onkeydown = this.checkKeyToMail();
      }
      if (fromCall === 'ccemail') {
        let documentvalue = document.getElementById(`searchccemailTagId`)
        documentvalue.onkeydown = this.checkKeyCcMail();
      }
      if (fromCall === 'bccemail') {
        let documentvalue = document.getElementById(`searchBccemailTagId`)
        documentvalue.onkeydown = this.checkKeyBccMail();
      }
    },
    keyUpfr() {
      let documentvalue = document.getElementById(`searchFemailFTagId`)
        documentvalue.onkeydown = this.checkKeyfrMail();
    },
    getEmilConfList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.EmailConfList(
          0,
          100,
          '',
          'addedDate',
          'DESC',
          true,
          response => {
            this.fromEMailList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.totalCount = response.Data.count
            if (this.fromEMailList.length === 1) {
              this.fromEmail = this.fromEMailList[0].emailId
            }
            if (this.fromEMailList.length === 0) {
              this.isShowFromEmail = false
              this.isDefault = true
            } else {
              this.isDefault = false
              this.isShowFromEmail = true
            }
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.getDefaultBodyData()
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  },
}
</script>
<style scoped>
</style>