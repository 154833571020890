<template>
  <div class="text-text1">
    <div>
      <div class="flex justify-between items-center">
         <p class="text-text1 font-bold heading-4 ">Line Items</p>
        <div class="heading-5">
          <span  @click="selectInvItems" class="text-primary font-bold cursor-pointer">+ Add Line Item</span>
        </div>
      </div>
    <div  class="mt-2" v-if="selectedItemList.length > 0">
        <div class="px-4  cursor-pointer hover:bg-gray1 border-b py-1" v-for="(data, index) in selectedItemList" :key="index + 'key'" @click="showitemDetail(data)">
        <div class="text-text2 text-sm flex mb-1 font-bold justify-between">
            <p class="pr-4" >{{data.itemName}}</p>
        </div>
        <div class="text-text2 flex">
            <p class="heading-6 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
        </div>
        <div class="text-text2 flex justify-between">
            <p class="heading-6">{{data.itemQty}} x {{data.itemPrice | amountOnlyNumber}}</p>
            <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
        </div>
        
        </div>
    </div>
    <div v-else>
      <NoRecords :alertMessage="`No records found.`" />
    </div>
    
    <div  class="" v-if="selectedItemList.length > 0">
    <div class="flex justify-between py-2 px-4">
        <p class="text-text2 font-bold w-full">Item Total</p>
        <p class="text-text2 font-bold w-full text-right">{{subTotal.toFixed(2) | amountOnlyNumber}}</p>
    </div>
    <div class="mt-2 py-2 heading-5 text-right">
        <span  @click="createInvoiceItem" class="text-primary font-bold cursor-pointer">Create Invoice from above Line Items</span>
    </div>
    </div>  
</div>
    <ItemSelection :itemsSelected="selectedItemList" v-if="itemSelect" />
    <SelectedItmDetail  :selectedItemForDetail="selectedItemForDetail" v-if="itemSelectDetail" />
   </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import SelectedItmDetail from '@/View/setting/items/components/SelectedItmDetail.vue'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '../../../components/noRecords.vue';
import ItemSelection from '../../../setting/items/components/ItemSelection.vue';
// import moment from 'moment'
export default {
  name: "customer",
  components: {
    ItemSelection,
    SelectedItmDetail,
    NoRecords,
  },
  mixins: [deboucneMixin],
  data() {
    return {
            subTotal: 0,
            itemSelect: false,
            itemSelectDetail: false,
            selectedItemForDetail: null,
            selectedItemList: [],
    };
  },
  created() {
  },
  computed: {
  },
  props: ['detailData'],
  mounted() {
    this.getVisitItemList()
    this.$root.$on("removeSelectDetailHandler", (response, data) => {
      console.log(response, data);
      if (response && this.itemSelectDetail) {
        this.deleteViItem(data.itemMappingId)
      }
      this.itemSelectDetail = false
    });
    this.$root.$on("itemSelectDetailHandler", (response, data) => {
      if (response && this.itemSelectDetail) {
        console.log(response, data);
        this.updateViItemDetail(data)
      }
      this.itemSelectDetail = false
    });
    this.$root.$on("itemSelectionHandler", (response, data, deletedItem) => {
      
      if (response && this.itemSelect) {
        this.addVisitItems(data, deletedItem)
      }

        this.itemSelect = false 
    });
  },
  methods: {
    createInvoiceItem () {
      if (this.detailData.requestType === 'job') {
        localStorage.setItem('detailData', JSON.stringify(this.detailData))
      } else {
        localStorage.setItem('detailData', JSON.stringify(this.detailData))
      }
      this.$router.push({name: 'createInvoice', query: {visitId: this.detailData.visitId, parentDetaiId: this.detailData.customerId, requestFrom: this.detailData.requestType === 'job' ? 'job' : 'visit' }})
    },
    getVisitItemList () {
      this.subTotal = 0
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getVisitItemList(
        this.detailData.requestType === 'job' ? 0 : parseInt(this.$route.params.visitId),
        this.detailData.requestType === 'job' ? this.detailData.jobId : 0,
          response => {
            this.selectedItemList = response.Data.tableRow !== null ? response.Data.tableRow : []
            if (this.detailData.requestType === 'job') {
              this.$root.$emit("getVisitItemsList", this.selectedItemList);
            }
            this.selectedItemList.map(data => {
              this.subTotal += data.itemAmount
            })
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    showitemDetail (data) {
      this.selectedItemForDetail = data
        this.itemSelectDetail = true
    },
    selectInvItems () {
        this.itemSelect = true
    },
    addVisitItems (addItem, deleteItm) {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      let data = {
        visitId: this.detailData.requestType === 'job' ? 0 : parseInt(this.$route.params.visitId),
        jobId: this.detailData.requestType === 'job' ? this.detailData.jobId : 0,
        itemList: addItem,
        deletedItemList: deleteItm,
      }
        MyJobApp.addVisititems(
        data,
        (response) => {
          document.body.style = 'overflow: visible;'
          this.getVisitItemList()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    deleteViItem (mapId) {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.deleteVisitItem(
        this.detailData.requestType === 'job' ? 0 : parseInt(this.$route.params.visitId),
        this.detailData.requestType === 'job' ? this.detailData.jobId : 0,
        mapId,
        (response) => {
          document.body.style = 'overflow: visible;'
          this.getVisitItemList()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    updateViItemDetail (data) {
       let updateVisitItem = {
          visitId: this.detailData.requestType === 'job' ? 0 : parseInt(this.$route.params.visitId),
          jobId: this.detailData.requestType === 'job' ? this.detailData.jobId : 0,
          itemTransactionId: data.itemTransactionId,
          itemMappingId: data.itemMappingId,
          itemMasterId: data.itemMasterId,
          itemCode: data.itemCode,
          itemName: data.itemName,
          itemDescription: data.itemDescription,
          itemPrice: data.itemPrice,
          itemQty: data.itemQty,
          itemUnit: data.itemUnit,
          itemAmount: data.itemAmount,
          isTaxable: data.isTaxable,
          taxRateList: []
        }
        if (data.taxRateList !== null) {
          data.taxRateList.map(tax => {
          let txAmount = data.itemPrice * tax.taxValue / 100
          let obj = {
            mappingId: 0,
          itemTransactionId: 0,
          taxId: tax.taxId,
          taxName: tax.taxName,
          taxValue: tax.taxValue,
          taxAmount: txAmount,
          }
          updateVisitItem.taxRateList.push(obj)
        })
        }
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.updateVisitItem(
        updateVisitItem,
        (response) => {
          document.body.style = 'overflow: visible;'
          this.getVisitItemList()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    }
  },
  beforeDestroy() {
    this.$root.$off("removeSelectDetailHandler");
    this.$root.$off("itemSelectionHandler");
    this.$root.$off("itemSelectDetailHandler");
  },
};
</script>
<style scoped>

</style>