<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span >Update Job</span>
            </div>
          </div>
          <div class="p-4">
            <div class="col-span-2 mb-4 sm:mb-0">
               <p class="label_css"  >Select Job Type</p>
               <div class="flex flex-wrap">
                 <span class="" v-for="(type, index) in jobTypeList" :key="index" >
                  <span @click="selectJobType(type)" :class="`mb-2 flex items-center justify-center w-fit heading-6 whitespace-nowrap border rounded-2xl  text-${SelectrdjobTypeId === type.jobTypeId ? 'primary' : ''} border-${SelectrdjobTypeId === type.jobTypeId ? 'primary' : 'gray2'} px-2 py-1  mr-1`">
                    {{type.jobTypeName}}</span>
                </span> 
               </div>
            </div>
            <div class="mt-4">
               <p class="label_css"  >Job Estimation</p>
               <div class="flex flex-wrap sm:gap-4">
                <div class="text-left gap-2 pt-1 pb-1 pl-2 flex items-center">
                    <div class=""><span>Hours:</span></div>
                    <div class="">
                        <button  class="timePick_btn"
                        @click="decreaseHours()"  small>
                        <span>-</span>
                        </button>
                    </div>
                    <input  @focus="$event.target.select()" class="minuteBox cust_text_field pl-3 w-12 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="hourInputValue"  v-mask="'##'">
                    <div class="ml-1">
                      <button  class="timePick_btn"
                        @click="increaseHours()"  small>+
                    </button>
                    </div>
                </div>
                <div class="text-left gap-2 pt-1 pb-1 pl-2 flex items-center">
                    <div class=""><span>Minutes:</span></div>
                    <div class="">
                        <button  class="timePick_btn"
                        @click="decreaseMin()"  small>
                        <span>-</span>
                        </button>
                    </div>
                    <input readonly  class="minuteBox cust_text_field pl-3 w-12 border rounded flext items-center justify-center px-1 py-1 " v-model="minInputValue"  v-mask="'##'">
                    <div class="ml-1">
                      <button  class="timePick_btn"
                        @click="increaseMin()"  small>+
                    </button>
                    </div>
                </div>
            </div>
            </div>
            <div class="mt-4">
              <div class="flex items-center py-1">
                 <CheckBoxInput :checkValue="isBillable" :label="`Billable`" :size="'small'" :disabled="false" @setIsActive="isBillable = !isBillable"/>
                  </div>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNote()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import CheckBoxInput from '../../components/checkBoxInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'addUpdatenote',
  components: {
    Button,
    CheckBoxInput,
  },
  data () {
    return {
        minInputValue: 0,
        hourInputValue: 0,
        jobTypeList: [],
        instruction: '',
        titleErr: '',
        isBillable: false
    }
  },
  props: ['detail', 'reqType'],
   created() {
  },
  mounted () {
    this.isBillable = this.detail.isBillable
    this.SelectrdjobTypeId = this.detail.jobTypeId
    document.body.style = 'overflow: hidden;'
    this.jobTypelist()
    this.hourInputValue = Math.floor(this.detail.jobEstimation / 60)
      this.minInputValue = this.detail.jobEstimation % 60
  },
  watch: {},
  methods: {
    decreaseHours() {
      if (this.hourInputValue > 0) {
        this.hourInputValue = Number(this.hourInputValue) - 1
      }
    },
    increaseHours() {
      if (this.hourInputValue < 99) {
        this.hourInputValue = Number(this.hourInputValue) + 1
      }
    },
    decreaseMin() {
      if (this.minInputValue === 15) {
        this.minInputValue = 0
      } else if (this.minInputValue > 45) {
        this.minInputValue = 45
      } else if (this.minInputValue > 30) {
        this.minInputValue = 30
      } else if (this.minInputValue > 15) {
        this.minInputValue = 15
      }
    },
    increaseMin() {
      if (this.minInputValue < 15) {
        this.minInputValue = 15
      } else if (this.minInputValue < 30) {
        this.minInputValue = 30
      } else if (this.minInputValue < 45) {
        this.minInputValue = 45
      }
    },
    jobTypelist() {
      MyJobApp.jobTypelist(
        (response) => {
          this.jobTypeList = response.Data
        },
        (err) => {
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: err.message,
            color: "error",
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    setDisplayEstimation() {
        let  estimation = (Number(this.hourInputValue) * 60) + Number(this.minInputValue);

      // Calculate hours and minutes
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes

      // Format the output
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + ' hr' : ''} ${hours > 0 && minutes > 0 ? "," : ''} ` + ` ${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} mins` : ''}`;

      this.displayEstimation = formattedTime
    },
    selectJobType(data) {
      this.hourInputValue = Math.floor(data.typeEstimationMinutes / 60)
      this.minInputValue = data.typeEstimationMinutes % 60
      this.SelectrdjobTypeId = data.jobTypeId
      this.setDisplayEstimation()
    },
    saveNote () {
        let isValid = true
        if (this.SelectrdjobTypeId < 0 || this.detail.jobId < 0) {
            isValid = false
        }
        let data = {
            jobId: this.detail.jobId,
            jobTypeId: this.SelectrdjobTypeId,
            jobEstimation: (Number(this.hourInputValue) * 60) + Number(this.minInputValue),
            isBillable: this.isBillable
        }
       if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
       MyJobApp.updateJobDetail(
        [],
        this.reqType,
         data,
         '',
        response => {
          document.body.style = 'overflow: visible;'
          this.$emit('updateJobTypeHandler', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
       }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$emit('updateJobTypeHandler', false)
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>
</style>